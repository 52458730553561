@import './assets/styles/main';


html,
body {
   font-family: 'Inter', sans-serif;
}


// Material Styles

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
   display: contents;
   position: absolute;
}

.mat-form-field-wrapper {
   width: 257px;
}

.mat-datepicker-actions {
   gap: 8px;
}

.mat-datepicker-actions > .btn {
   padding: 14px 16px;
}

.mat-form-field {
   position: absolute !important;
   right: 0 !important;
}

// Retirada css deeppurple

.mat-snack-bar-container {
   color: inherit !important;
   background: inherit !important;
   box-shadow: inherit !important;
}

// NgPrime Styles

.p-datatable .p-datatable-thead > tr > th {
   font-family: $font-family-kavalli !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
   border-color: $primary-color !important;
   background: $primary-color !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
   box-shadow: none !important;
}

.p-datatable .p-datatable-header {
   display: flex;
   justify-content: flex-end;
}

// Material Styles

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
   box-shadow: none !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
   background-color: transparent !important;
}


// Material Checkbox

.mdc-form-field {
   display: flex;
   flex-direction: row-reverse;
}
.mat-mdc-checkbox label {
   font-family: $font-family-kavalli;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
   border-color: $primary-color !important;
   background-color: $primary-color !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
   color: #fff !important;
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
   background: transparent !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
   border-color: $gray-medium;
}